//fonts
$primaryFont: 'Montserrat', sans-serif;
$themeFont: 'trajan-pro-3', serif;

//colors
$themeBlue: #103555;
$themeDarkBlue: #0a1f31;
$themeBlueLighter: #164268;
$themeDark: #555553;
$themeLightDark: #8B8B89;
$themeLight: #E6E6E5;
$themeGreen: #7B752E;

//screen sizes

$tablet: 768px;
$tabletMax: 1023px;
$laptop: 1024px;
$desktop: 1200px;