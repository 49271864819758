.m_btn{
    @include buttonStyles;
}

.m_btn2{
    @include buttonInvertedStyles;
    @include transition(0.5s);
    position: relative;
    left: 0;
    &._sending{
        left: 100vw;
    }
}

.m_heading{
    color: $themeBlue;
}

.m_footer-heading,
h2.m_footer-heading{
    @include subheadingStyles;
}

.m_section{
    position: relative;
}

.m_inner{
    width: calc(100% - 20px);
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    @media(min-width: $tablet){
        &.m_swop{
            .m_column{
                &:nth-of-type(1){
                    order: 2;
                }
                
            }
        }
    }
   
    
    @media(min-width: $tablet){
        width: calc(100% - 30px);
    }
    @media(min-width: $laptop){
        width: calc(100% - 40px);
    }
}

.m_column{
    padding: 0 10px;
    @media(min-width: $tablet){
        padding: 0 15px;
    }
    @media(min-width: $laptop){
        padding: 0 20px;
    }
}

.m_column-12{
    width: 100%!important;
}

.m_column-6{
    width: 100%;
    @media(min-width: $tablet){
        width: 50%;
    }
}

.m_column-4{
    width: 100%;
    @media(min-width: $tablet){
        width: 50%;
    }
    @media(min-width: $laptop){
        width: 33.33333%;
    }
}

.m_column-3{
    width: 100%;
    @media(min-width: $tablet){
        width: 50%;
    }
    @media(min-width: $laptop){
        width: 25%;
    }
}

.m_hamburger-icon{
    width: 30px;
    height: 30px;
    position: relative;
    background-color: transparent;
    border: none;
    @include transition;
    cursor: pointer;
    span{
        display: block;
        width: 100%;
        height: 2px;
        background-color: $themeBlue;
        position: absolute;
        transform: translate(-50%, -50%);
        @include transition;
        &:nth-of-type(1){
            left: 50%;
            top: 20%;
        }
        &:nth-of-type(2){
            left: 50%;
            top: 50%;
        }
        &:nth-of-type(3){
            left: 50%;
            top: 80%;
        }
    }
    &.m_open{
        transform: rotate(-180deg);
        span{
            &:nth-of-type(1){
                top: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
            }
            &:nth-of-type(2){
                visibility: hidden;
                opacity: 0;
            }
            &:nth-of-type(3){
                top: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
    }
}



.m_locale-select{
    position: relative;
    height: 40px;
    width: 115px;
    cursor: pointer;
    label{
        position: absolute; 
        left: 0;
        top: 0; 
        background-color: #fff;   
        width: 100%; 
        height: 100%; 
        padding: 7px 15px;
        
        &:after{
            content: "";
            display: block;
            width: 9px;
            height: 9px;
            border-right: 1px solid $themeBlue;
            border-bottom: 1px solid $themeBlue;
            transform: rotate(45deg);
            position: absolute;
            top: 14px;
            right: 15px;
        }
    }
    select{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%; 
        height: 100%;
        opacity: 0;
        z-index: 1;
    }
    @media(min-width: $laptop){
        width: 128px;
        label{
            position: absolute; 
            left: 0;
            top: 0; 
            background-color: $themeBlue;   
            width: 100%; 
            height: 100%; 
            padding: 7px 20px;
            border: 1px solid $themeBlue;
            color: #fff;
            &:after{
                content: "";
                display: block;
                width: 9px;
                height: 9px;
                border-right: 1px solid #fff;
                border-bottom: 1px solid #fff;
                transform: rotate(45deg);
                position: absolute;
                top: 14px;
                right: 20px;
            }
        }
    }
}

.m_horline{
    width: 100%;
    max-width: 350px;
    height: 2px;
    background-color: $themeGreen;
}

.m_overlay{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @media(min-width: $laptop){
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 70%);
    }
}

.hexagon {
    clip-path: polygon(75% 5%, 100% 50%, 75% 95%, 25% 95%, 0 50%, 25% 5%);
}

.m_services-column{
    padding-bottom: 20px;
    svg{
        fill: $themeDarkBlue;
    }
    a{
        background-color: $themeBlue;
        display: block;
        padding: 60px 30px;
        text-align: center;
        position: relative;
        height: 100%;
        h4{
            color: $themeLight;
        }
        .m_img{
            width: 150px;
            margin: 0 auto;
        }
    }
    .m_services-overlay{
        color: $themeLight;
        .m_p{
            margin-bottom: 30px;
        }
    }
    @media(min-width: $tablet){
        padding-bottom: 30px;
        
    }
    @media(min-width: $laptop){
        padding-bottom: 40px;
        h4{
            font-size: 22px;
            margin-left: -20px;
            margin-right: -20px;
        }
        .m_services-overlay{
            visibility: hidden;
            opacity: 0;
            @include transition;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
            color: $themeDark;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 60px 30px;
            .m_p{
                display: block;
            }
        }
        a{
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // height: 400px;
            &:hover{
                .m_services-overlay{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    @media(min-width: 1200px){
        h4{
            font-size: 27px;
        }
    }
    @media(min-width: 1300px){
        h4{
            font-size: 30px;
        }
    }
}

.n_btn-green{
    background-color: $themeGreen;
    display: inline-block;
    padding: 5px 18px;
    cursor: pointer;
    @include transition(0.15s);
    color: #fff;
    @media(min-width: $laptop){
        opacity: 0.8;
        &:hover{
            opacity: 1;
        }
    }
}

.m_themeFont{
    @include themeFontStylesThin;
}

.m_box{
    margin-bottom: 20px;
    .m_p{
        font-size: 22px;
    }
    .m_box-overlay{
        &:nth-of-type(1){
            background-position: center;
            background-size: cover;
            .m_h3{
                text-align: center;
                background-color: rgba(255, 255, 255, 0.808);
                padding: 150px 0 30px 0;
                margin: 0;
                color: $themeBlue;
            }
        }
        &:nth-of-type(2){
            background-color: #fff;
            img{
                width: 200px;
                margin: 0 auto 20px auto;
            }
        }
        &:nth-of-type(2){
            .m_inner{
                padding-top: 40px;
                padding-bottom: 40px;
                @media (max-width: $tabletMax){
                    margin: 0;
                    .m_column{
                        padding: 0;
                        p{
                            padding-right: 15px;
                        }
                    }
                }
                .m_p{
                    margin-top: 0;
                }
                .m_btn2{
                    display:none;
                }
            }
        }
    }
    
    @media(min-width: $tablet){
        margin-bottom: 30px;
    }
    @media(min-width:$laptop){
        margin-bottom: 60px;
        position: relative;
        border: 1px solid $themeLight;
        @include transition;
        .m_box-overlay{
            @include transition;
            &:nth-of-type(1){
                padding-top: 400px;
                .m_h3{
                    padding: 50px 0;
                }
            }
            &:nth-of-type(2){
                visibility: hidden;
                opacity: 0;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: $themeLight;
                .m_inner{
                    padding: 60px 0;
                    height: 100%;
                    .m_btn2{
                        display:inline-block;
                    }
                    .m_column {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        overflow-y: auto;
                    }
                }
            }
        }
        &:hover{
            .m_box-overlay{
                &:nth-of-type(2){
                    visibility: visible;
                    opacity: 1;
                }
            }            
        }
    }
}

.m_ul, .m_sm-ul{
padding-left: none;
}
.m_li, .m_sm-li{
    list-style: none;
}

.m_sm-ul{
    display: flex;
    .m_sm-li{                    
        a{
            background-color: $themeLight;
            display: inline-block;
            width: 40px;
            height: 40px;
            padding: 12px;
            border: 1px solid $themeDark;
            img{
                width: 15px;
                height: 15px;
            }
        }
    }
}

.m_input-group{
    display: flex;
    flex-wrap: wrap;
    .m_input-element{
        width: 100%;
        margin-bottom: 30px;
        label{
            display: block;
        }
        input, select{
            display: block;
            width: 100%;
            height: 40px;
            border-radius: 0;
            border: 1px solid $themeLight;
            background-color: $themeLight;
            padding: 0 10px;
        }
        textarea{
            display: block;
            width: 100%;
            height: 220px;
            border-radius: 0;
            border: 1px solid $themeLight;
            background-color: $themeLight;
            padding: 0 10px;
        }
    }
    @media(min-width: $laptop){
        flex-wrap: nowrap;
        margin-left: -15px;
        margin-right: -15px;
        .m_input-element{
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.m_map{
    width: 100%;
    position: relative;
    height: 0;
    padding-bottom: 80%;
    margin: 60px 0 40px 0;
    iframe{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
    @media(min-width: $tablet){
        padding-bottom: 50%;
    }
    @media(min-width: $laptop){
        padding-bottom: 42%;
    }
}

.m_tabs{
    .m_ul{
        display: flex;
        justify-content: center;
        padding-left: 0;
        background-color: $themeLight;
        overflow-x: auto;
        .m_li{
            padding: 0 5px;
            .n_tab-btn{
                background-color: transparent;
                border:none;
                position: relative;
                cursor: pointer;
                padding: 20px 0;
                font-size: 12px;
                &:after{
                    content: "";
                    display: block;
                    width: 80%;
                    height: 2px;
                    background-color: $themeGreen;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 77%;
                    visibility: hidden;
                    opacity: 0;
                    @include transition;
                }
                &.m_active, &:hover{
                    &:after{
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
        @media(min-width: 480px){
            .m_li{
                padding: 0 15px;
                .n_tab-btn{
                    font-size: 14px;
                }
            }
        }
        @media(min-width: 560px){
            .m_li{
                padding: 0 20px;
                .n_tab-btn{
                    font-size: 16px;
                }
            }
        }
        @media(min-width: $laptop){
            justify-content: flex-end;
        }
    }
}

._error-message{
    color: red;
    font-weight: 700;
}

._popup-container{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    z-index: 1000;
    visibility: visible;
    opacity: 1;
    @include transition;
    ._overlay{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
    }
    ._popup-panel{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 60px);
        max-width: 600px;
        background-color: #fff;
        padding: 30px;
        .m_btn{
            margin: 0 auto;
            display: block;
        }
    }
    &._hide{
        visibility: hidden;
        opacity: 0;
    }
}