html {
    box-sizing: border-box;
    // scroll-behavior: smooth;
}
*{
    @include primaryFontStyles;
}
*, *:before, *:after {
    box-sizing: inherit;
}
body{
    @include primaryFontStyles;
    color: $themeDark;
    margin: 0;
    font-size: 16px;
}

h1, h2{
    @include themeFontStyles;
    font-size: 24px;
    line-height: 30px;
    @media(min-width: $tablet){
        font-size: 35px;
        line-height: 45px;
    }
    @media(min-width: $laptop){
        font-size: 43px;
        line-height: 55px;
    }
    @media(min-width: $desktop){
        font-size: 50px;
        line-height: 65px;
    }
}

h3{
    font-size: 28px;
    line-height: 34px;
    @media(min-width: $laptop){
        font-size: 35px;
        line-height: 44px;
    }
}

h4{
    font-size: 24px;
    line-height: 30px;
    @media(min-width: $laptop){
        font-size: 30px;
        line-height: 40px;
    }
}

p{
    font-size: 16px;
    line-height: 26px;
    strong{
        font-size: 24px;
    }
    &.m_p{
        @media(min-width: $desktop){
            font-size: 22px;
            line-height: 35px;
            strong{
                font-size: 30px;
            }
        }
    }
}

a{
    text-decoration: none;
    color: inherit;
}

ul{
    padding-left: 15px;
}

li{
    font-size: 16px;
    line-height: 26px;
}

figure{
    margin: 0;
}

img{
    display: block;
    width: 100%;
    max-width: 100%;
}

td, th, input, textarea, button, label, select{
    font-size: 16px;
    line-height: 26px;
}