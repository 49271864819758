.m_content{
    margin-top: 80px;
    @media(min-width: $laptop){
        margin-top: 120px;
    }
}

.m_section-home-1{
    height: 60vh;
    min-height: 500px;
    background-image: url('../img/fms-29.jpg');
    background-position: 0% 22%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 0;
    .m_inner{
        justify-content: flex-end;
        .m_column{
            z-index: 1;
            position: relative;
            top: 30px;
            .n_column-inner{
                width: auto;
                max-width: 340px;
                background-color: #fff;
                border-radius: 50px 0 50px 0;
                text-align: right;
                padding: 10px 20px;
                p{
                    @include themeFontStyles;
                    &:nth-of-type(1){
                        margin-bottom: 0;
                    }
                    &:nth-of-type(2){
                        color: $themeGreen;
                    }
                }
                .m_p{
                    span{
                        font-size: 24px;
                        @include themeFontStyles;
                    }
                    @media(min-width: $laptop){
                        font-size: 30px;
                        line-height: 40px;
                        color: $themeBlue;
                        @include themeFontStyles;
                        span{
                            font-size: 40px;
                            @include themeFontStyles;
                        }
                    }
                }
                @media(min-width: $tablet){
                    padding: 10px 30px;                    
                    border-radius: 0;
                    max-width: 350px;
                    
                }
                @media(min-width: $laptop){
                    padding: 10px 40px;
                    border-radius: 0;
                    max-width: 600px;
                }
            }
            @media(min-width: $laptop){
                top: 0;
            }
        }
    }
    @media(min-width: $laptop){
        height: calc(100vh - 120px);
        background-image: url('../img/fms-30.jpg');
        margin-bottom: 50px;
    }
}

.m_section-home-2{
    margin: 0 0 60px 0;
    background-color: $themeDark;
    color: #fff;
    h1{
        color: #fff;
    }
    .m_column{
        
        &:nth-of-type(1){
            display: none;
        }
        &:nth-of-type(2){
            text-align: center;  
            padding-top: 90px;
            padding-bottom: 90px;         
        }
    }
    .m_background-pattern-img{
        display: none;
    }
    @media(min-width: $tablet){
        margin: 100px 0;
        background-color: #fff;
        color: $themeDark;
        h1{
            color: $themeBlue;
            margin-top: 60px;
        }
        .m_column{
            &:nth-of-type(1){
                display: block;
                img{
                    border-radius: 300px 0 300px 0;
                    max-width: 550px;
                }
            }
            &:nth-of-type(2){
                text-align: right; 
                padding-top: 0;
                padding-bottom: 0;
                z-index: 3;
            }
        }
        .m_background-pattern-img{
            display: block;
            position: absolute;
            top: 95%;
            left: 55%;
            transform: translate(-50%, -50%);
            width: 900px;
            max-width: 70%;
            z-index: 0;
        }
    }
    @media(min-width: $desktop){
        .m_column{
            &:nth-of-type(1){
                img{
                    border-radius: 350px 0 350px 0;
                }
            }
        }
    }
}

.m_section-home-3{
    margin: 60px 0;
    .m_column{
        @include infoColumnStyles;
        width: 100%;
        @media(min-width: $tablet){
            width: 50%;
        }
        @media(min-width: $laptop){
            width: 33.33333%;
        }
    }
    @media(min-width: $tablet){
        margin: 150px 0 100px 0;
    }
    @media(min-width: $laptop){
        margin: 250px 0 100px 0;
    }
    .m_btn{
        margin-top: 60px;
    }
}

.m_section-home-4{
    margin: 60px 0 0 0;
    background-color: $themeLight;
    padding: 60px 0;
    .m_column{
        text-align: center;
        img{
            width: 250px;
            margin: 0 auto;
            border-radius: 200px;
        }
    }
    h2{
        margin-top: 0;
        margin-bottom: 60px;
    }
    @media(min-width: $laptop){
        margin: 100px 0 0 0;
        padding: 100px 0;
    }
}

.m_section-about-1{
    background-image: url('../../assets/img/fms-46.jpg');
    height: 0;
    padding-bottom: 65%;
    background-position: top;
    background-size: cover;
    @media(min-width: $tablet){
        display:none;
    }
}

.m_section-about-2{
    padding: 100px 0;
    background-color: $themeDark;
    color: #fff;
    text-align: center;
    h1{
        margin-top: 0;
        color: #fff;
    }
    .m_horline{
        display: none;
    }
    @media(min-width: $tablet){
        background-color: transparent;
        color: $themeDark;
        text-align: left;
        h1{
            color: $themeBlue;
            margin-bottom: 20px;
        }
        p{
            max-width: 705px;
        }
        .m_horline{
            display: block;
            margin-top: 30px;
        }
    }
    @media(min-width: $laptop){
        p{
            max-width: 970px;
        }
    }
}

.m_section-about-3{
    background-image: url('../../assets/img/fms-46.jpg');
    height: 0;
    padding-bottom: 50%;
    background-position: top;
    background-size: cover;
    display:none;
    @media(min-width: $tablet){
        display:block;
    }
}

.m_section-about-4{
    padding: 60px 0;
    .m_inner{
        &:nth-of-type(1){
            text-align: center;
            h2{
                margin-bottom: 60px;
                margin-top: 0;
            }
        }
        &:nth-of-type(2){
            .m_column{
                @include infoColumnStyles;
                width: 100%;
                &:nth-of-type(5){
                    padding-bottom: 0;
                }
                p{
                    display: none;
                }
                h4{
                    font-size: 16px!important;
                    line-height: 26px;
                    margin-bottom: 0;
                }
                @media(min-width: $tablet){
                    width: 33.3333%;
                }
                @media(min-width: $laptop){
                    width: 20%;
                }
                @media(min-width: $desktop){
                    width: 20%;
                    h4{
                        font-size: 22px!important;
                        line-height: 32px;
                    }
                }
            }
        }
    }
    @media(min-width: $laptop){
        padding: 100px 0 100px 0;
    }
}

.m_section-about-5{
    background-color: $themeBlue;
    color: #fff;
    padding: 60px 0;
    text-align: center;
    img{
        width: 200px;
        margin: 0 auto;
    }
    @media(min-width: $laptop){
        padding: 100px 0;
    }
}

.m_section-about-6{
    padding: 60px 0;
    background-color: $themeLight;
    .m_inner{
        padding: 30px 0;
        img{
            max-width: 400px;
            margin: 0 auto 30px auto;
        }
        .m_column{
            h4{
                margin: 0;
            }
            .m_p{
                margin: 0 0 30px 0;
            }
            .m_horline{
                display: none;
            }
            .m_btn{
                margin-top: 20px;
            }
            &:nth-of-type(2){
                padding-bottom: 30px;
            }
        }
    }
    @media(min-width: $tablet){
        padding: 50px 0;
        .m_inner{
            align-items: center;
            padding: 50px 0;
            img{
                max-width: 100%;
            }
            .m_column{
                h4{
                    margin: 0;
                }
                .m_horline{
                    display: block;
                    margin: 30px 0;
                }
            }
            &:nth-of-type(2n){
                .m_column{
                    text-align: right;
                    .m_horline{
                        margin-left: auto;
                    }
                }
            }
        }
    }
}

.m_section-services-1{
    height: 500px;
    background-image: url('../img/finance-4858797_1280.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 0;
    .m_inner{
        justify-content: flex-end;
        .m_column{
            z-index: 1;
            position: relative;
            bottom: -30px;
            .n_column-inner{
                width: auto;
                max-width: 270px;
                background-color: #fff;
                border-radius: 50px 0 50px 0;
                text-align: right;
                padding: 10px 20px;
                p{
                    @include themeFontStyles;
                    &:nth-of-type(1){
                        margin-bottom: 0;
                    }
                    &:nth-of-type(2){
                        color: $themeGreen;
                    }
                }
                .m_p{
                    span{
                        font-size: 24px;
                        @include themeFontStyles;
                    }
                    @media(min-width: $laptop){
                        font-size: 30px;
                        line-height: 40px;
                        color: $themeBlue;
                        @include themeFontStyles;
                        span{
                            font-size: 40px;
                            @include themeFontStyles;
                        }
                    }
                }
                @media(min-width: $tablet){
                    padding: 10px 30px;
                    max-width: 500px;
                    border-radius: 0;
                    
                }
                @media(min-width: $laptop){
                    padding: 10px 40px;
                    border-radius: 0;
                }
            }
            @media(min-width: $laptop){
                top: 0;
            }
        }
    }
    @media(min-width: $laptop){
        height: 600px;
        margin-bottom: 50px;
    }
}

.m_section-services-2{
    padding: 60px 0;
    h1{
        margin: 0 0 60px 0;
        text-align: center;
    }
    @media(min-width: $laptop){
        padding: 100px 0;
    }
    .m_column-btn{
        text-align: center;
        .m_btn{
            margin-top: 60px;
        }
    }
}

.m_section-products-1{
    height: 500px;
    background-image: url('../img/business-card-2918332_1920.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 0;
    .m_inner{
        justify-content: flex-end;
        .m_column{
            z-index: 1;
            position: relative;
            bottom: -30px;
            .n_column-inner{
                width: 100%;
                max-width: 500px;
                background-color: #fff;
                border-radius: 50px 0 50px 0;
                text-align: right;
                padding: 10px 20px;
                p{
                    @include themeFontStyles;
                    &:nth-of-type(1){
                        margin-bottom: 0;
                    }
                    &:nth-of-type(2){
                        color: $themeGreen;
                    }
                }
                .m_p{
                    span{
                        font-size: 24px;
                        @include themeFontStyles;
                    }
                    @media(min-width: $laptop){
                        font-size: 30px;
                        line-height: 40px;
                        color: $themeBlue;
                        @include themeFontStyles;
                        span{
                            font-size: 40px;
                            @include themeFontStyles;
                        }
                    }
                }
                @media(min-width: $tablet){
                    padding: 10px 30px;
                    max-width: 500px;
                    border-radius: 0;
                    
                }
                @media(min-width: $laptop){
                    padding: 10px 40px;
                    border-radius: 0;
                    max-width: 350px;
                }
            }
            @media(min-width: $laptop){
                top: 0;
            }
        }
    }
    @media(min-width: $laptop){
        height: 600px;
        margin-bottom: 50px;
    }
}

.m_section-products-2{
    padding: 60px 0;
    h1{
        margin: 0 0 60px 0;
    }
    @media(min-width: $laptop){
        padding: 100px 0;
    }
}

.m_section-contact{
    padding: 60px 0 0 0;
    h1{
        margin: 0 0 60px 0;
    }
    p{
        margin-bottom: 40px;
        span{
            @include themeFontStyles;
        }
    }
    .m_horline{
        display: none;
    }
    @media(min-width: $laptop){
        padding: 100px 0 0 0;
        .m_horline{
            display: block;
            margin: 30px 0;
        }
    }
}