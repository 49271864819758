.m_header{
    position: fixed;
    height: 80px;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 100;
    
    .m_inner{
        align-items: center;
        justify-content: space-between;
        height: 100%;
        flex-wrap: nowrap;
    }
    .m_logo{
        width: 77px;
        overflow: hidden;
        display: block;
        img{
            width: 160px;
            max-width: unset;
        }
    }
    .m_li{
        a{
            position: relative;
            &:after{
                content: "";
                display: block;
                width: 50px;
                height: 2px;
                background-color: $themeGreen;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 77%;
                visibility: hidden;
                opacity: 0;
                @include transition;
            }
            @media (max-width: $tabletMax){
                &.m_active{
                    background-color: $themeBlueLighter;
                }
                
                &:after{
                    display: none;
                }
            }
            &.m_active, &:hover{
                &:after{            
                    visibility: visible;
                    opacity: 1;
                }
            }
        }    
    }
    @media(min-width: $laptop){
        height: 120px;
        .m_logo{
            width: auto;
            img{
                width: 240px;
            }
        }
    }
    .m_column-nav{
        width: 0;
        height: 100%;   
        @media (max-width: $tabletMax)  {
            .m_nav{
                visibility: hidden;
                opacity: 0;
                @include transition;
                position: fixed;
                z-index: 1;
                top: 80px;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: $themeBlue;
                color: #fff;
                .m_ul{
                    margin-top: 60px;
                    padding-left: 0;
                    .m_li{
                        text-align: center;
                        a{
                            display: block;
                            padding: 15px 0;
                            font-size: 22px;
                            &.m_btn-contact{
                                color: #fff;
                                height: auto;
                            }
                        }
                    }
                }
                
            }
            &.m_show{
                .m_nav{
                    visibility: visible;
                    opacity: 1;
                }            
            }  
        }   
        @media(min-width: $laptop){
            width: 100%;
            .m_nav{
                height: 100%;
                .m_ul{
                    display: flex;
                    justify-content: flex-end;
                    height: 100%;
                    align-items: center;
                    margin: 0;
                    .m_li{
                        a{
                            display:block;
                            padding: 20px 14px;
                            // font-size: 20px;
                            &.m_btn-contact{
                                @include buttonStyles;
                                &:after{
                                    display:none;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media(min-width: $desktop){
            .m_nav{
                .m_ul{
                    .m_li{
                        a{
                            padding: 20px;
                        }
                    }
                }
            }
        }
    }
    .m_column-widgets{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-left: 0;
        .m_hamburger-icon{
            margin-left: 10px;
            @media(min-width: $laptop){
                display: none;
            }
        }
    }
}

.m_footer{
    background-color: $themeBlue;
    color: #fff;
    
    .m_inner{
        &:nth-of-type(1){
            padding: 60px 0 15px 0;
            .m_footer-heading{
                max-width: 365px;
                margin: 0 auto;
            }
            .m_column{
                width: 100%;
                text-align: center;
                &:nth-of-type(1){
                    border-bottom: 1px solid $themeBlueLighter;
                }
                &:nth-of-type(2){
                    border-bottom: 1px solid $themeBlueLighter;
                }
                &:nth-of-type(3){
                    border-bottom: 1px solid $themeBlueLighter;
                }
                &:nth-of-type(4){
                    border-bottom: 1px solid $themeBlueLighter;
                }
            }
        }
        &:nth-of-type(2){
            padding: 15px 0 15px 0;
            justify-content: center;
            
        }
        &:nth-of-type(3){
            .m_column{
                text-align: center;
                width: 100%;
                p{
                    span{
                        color: $themeLightDark;
                    }
                }
            }
        }
    }
    .m_footer-ul{
        padding-left: 0;
        .m_li{
            a{
                display: block;
                padding: 6px 0;
                white-space: pre;
                line-height: 22px;
            }
        }
    }
    @media(min-width: $laptop){
        a{
            opacity: 1;
            @include transition(0.05s);
            &:hover{
                opacity: 0.8;
            }
        }
        .m_inner{
            &:nth-of-type(1){
                padding: 90px 0 30px 0;
                flex-wrap: nowrap;
                justify-content: space-between;
                .m_column{
                    border-bottom: none!important;
                    width: auto;
                }
                .m_footer-heading{
                    margin-top: 18px;
                }
            }
            &:nth-of-type(2){
                justify-content: flex-end;
            }
            &:nth-of-type(3){
                flex-wrap: nowrap;
                justify-content: space-between;
                .m_column{
                    width: auto;
                }
            }
        }
    }
}