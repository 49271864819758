@mixin primaryFontStyles{
    font-family: $primaryFont;
    font-weight: 300;
}

@mixin themeFontStyles{
    font-family: $themeFont;
    font-weight: 600;
}

@mixin themeFontStylesThin{
    font-family: $themeFont;
    font-weight: 300;
}

@mixin subheadingStyles{
    @include themeFontStyles;
    font-size: 24px;
    line-height: 30px;
    @media(min-width: $laptop){
        font-size: 30px;
        line-height: 55px;
    }
}

@mixin buttonStyles{
    height: 40px;
    padding: 6px 20px;
    border: 2px solid $themeBlue;
    border-radius: 0;
    color: $themeBlue;
    font-size: 16px;
    display: inline-block;
    line-height: 25px;
    cursor: pointer;
    text-align: center;
    background-color: transparent;
    @include transition;
    &:hover{
        background-color: $themeBlue;
        color: #fff;
    }
    &.m_btn-big-text{
        @media(min-width: $laptop){
            font-size: 20px;
        }
    }
    
}

@mixin buttonInvertedStyles{
    height: 40px;
    padding: 6px 20px;
    border: 2px solid $themeBlue;
    border-radius: 0;
    color: #fff;
    font-size: 16px;
    display: inline-block;
    line-height: 25px;
    cursor: pointer;
    text-align: center;
    background-color: $themeBlue;
    @include transition;
    &:hover{
        background-color: transparent;
        color: $themeBlue;
    }
    &.m_btn-big-text{
        @media(min-width: $laptop){
            font-size: 20px;
        }
    }
}

@mixin transition($speed: 0.25s, $delay: 0s){
    transition: $speed;
    -webkit-transition: $speed;
    -moz-transition: $speed;
    -ms-transition: $speed;
    transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    transition-delay: $delay;
    -webkit-transition-delay: $delay;
    -moz-transition-delay: $delay;
    -ms-transition-delay: $delay;
}

@mixin infoColumnStyles{
    text-align: center;
    padding-bottom: 30px;
    .m_img{
        width: 120px;
        height: 120px;
        margin: 0 auto;
        display: block;
        position: relative;
        background-color: $themeBlue;
        border-radius: 100px;
        @include transition(0.4s, 0.1);
        // border: 1px solid $themeBlue;
        svg{
            position: absolute;
            width: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            fill:#fff;
            @include transition(0.4s, 0.1);
            path{
                fill:#fff;
                @include transition(0.4s, 0.1);
            }                
        }
        &:hover{
            background-color: #fff;
            svg{
                fill:$themeBlue;
                path{
                    fill:$themeBlue;
                }
                
            }
        }
    }
}